<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex">
      <div class="el-lt" style="width: 88%">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="调出备货渠道">
              <Select
                v-model="form.outStockChannelIdList"
                :select-options="StockChannelOptions"
                :configuration="{
                  key: 'channelId',
                  label: 'channelName',
                  value: 'channelId',
                }"
                clearable
                multiple
                filterable
                collapse-tags
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="调入备货渠道">
              <Select
                v-model="form.inStockChannelIdList"
                :select-options="StockChannelOptions"
                :configuration="{
                  key: 'channelId',
                  label: 'channelName',
                  value: 'channelId',
                }"
                clearable
                multiple
                filterable
                collapse-tags
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="调入仓库">
              <el-select
                v-model="form.inWarehouseCodeList"
                value-key="id"
                clearable
                multiple
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in warehouseOption"
                  :key="item.id"
                  :label="item.warehouseName"
                  :value="item.logicWarehouseCode"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="Style">
              <el-select
                v-model="form.styleIdList"
                value-key="id"
                clearable
                multiple
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Color" :class="$i18n.locale">
              <el-select
                v-model="form.colorList"
                clearable
                filterable
                multiple
                id:placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Size" :class="$i18n.locale">
              <el-select
                v-model="form.sizeList"
                clearable
                filterable
                multiple
                id:placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="品牌">
              <el-select
                v-model="form.brandIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="类型">
              <el-select
                v-model="form.transferTypeDictList"
                :placeholder="$t('page.selectPlaceholder')"
                multiple
                filterable
                clearable
              >
                <el-option
                  v-for="item in _getAllCommodityDict('REPLENISH_TYPE_DICT')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Sku">
              <el-input
                v-model="form.sku"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="销售预测版本" :class="$i18n.locale">
              <el-select
                v-model="form.versionCode"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in dsbVertionOptions"
                  :key="item.versionCode"
                  :label="item.versionCode"
                  :value="item.versionCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="操作人" :class="$i18n.locale">
              <el-select
                v-model="form.operateId"
                clearable
                filterable
              >
                <el-option
                  v-for="item in useroptions"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="操作时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 12%">
        <el-col class="row-center">
          <el-button type="primary" :loading="Loading" @click="queryClick(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <!-- <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button> -->
        </el-col>
      </el-row>
    </el-form>

    <el-row :span="24" class="mb-3">
      <el-col :span="24" style="text-align: right">
        <el-button v-permission="'exportA'" class="mr-3" type="primary" :loading="exportLoading" @click="exportDetail">导出</el-button>
      </el-col>
    </el-row>
    <!-- <template slot-scope="scope">
          <router-link
            :to="{
              path: '/purchasbusiness/purchasplan/vieworder',
              query: { id: scope.row.id, disabled: true },
            }"
            style="color: #1890ff"
          >{{ scope.row.purchasePlanCode }}</router-link>
        </template> -->
    <vxe-table
      ref="multipleTable"
      v-loading="Loading"
      border
      show-overflow
      max-height="600"
      :scroll-x="{ enable: false, gt: -1 }"
      :scroll-y="{ enable: false, gt: -1 }"
      :data="tableDatas"
      align="center"
      class="mb-35"
    >
      <vxe-column title="序号" type="seq" width="60" />
      <!-- <vxe-column
        field="transferOrderCode"
        title="调拨单号"
        align="center"
        min-width="100"
      /> -->
      <vxe-column field="transferOrderCode" title="调拨单号" width="170" align="center">
        <template slot-scope="scope">
          <router-link
            :to="{ path: '/replenishment-allocation-management/transfer-order-management/details', query: { transferOrderCode: scope.row.transferOrderCode }}"
            style="color:#1890ff;"
          >{{ scope.row.transferOrderCode }}</router-link>
        </template>
      </vxe-column>
      <vxe-column field="stateI18" title="调拨单状态" align="center" min-width="100" />
      <vxe-column field="transferType" title="补货类型" align="center" min-width="100" />
      <vxe-column
        field="brandName"
        title="品牌"
        align="center"
        min-width="100"
      />
      <vxe-column field="style" title="Style" align="center" min-width="100" />
      <vxe-column field="color" title="Color" align="center" min-width="100" />
      <vxe-column field="size" title="Size" align="center" min-width="100" />
      <vxe-column field="sku" title="Sku" align="center" min-width="100" />
      <vxe-column
        field="turnOver"
        title="TurnOver"
        align="center"
        min-width="100"
      />
      <vxe-column field="replenishCycle" title="补货周期" align="center" min-width="100">
        <template slot-scope="scope">{{ scope.row.replenishCycle ? scope.row.replenishCycle : 0 }}</template>
      </vxe-column>
      <vxe-column
        field="replenishDemand"
        title="补货需求"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="versionCode"
        title="销售预测版本"
        align="center"
        min-width="100"
      />
      <vxe-column field="predictSales" title="预测销量" align="center" min-width="100">
        <template slot-scope="scope">{{ scope.row.predictSales ? scope.row.predictSales : 0 }}</template>
      </vxe-column>
      <vxe-column field="predictReturn" title="预测退货" align="center" min-width="100">
        <template slot-scope="scope">{{ scope.row.predictReturn ? scope.row.predictReturn : 0 }}</template>
      </vxe-column>
      <vxe-column
        field="overseasPlatAvlStock"
        title="平台仓可用库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="overseasPlatTransStock"
        title="平台仓在途库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="overseasSelfAvlStock"
        title="海外自管仓可用库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="overseasSelfTransStock"
        title="海外自管仓在途库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="overseasThirdAvlStock"
        title="海外三方仓可用库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="overseasThirdTransStock"
        title="海外三方仓在途库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="overseasNotSendStock"
        title="海外仓未发库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="overseasTransUncleared"
        title="海外仓调拨未结"
        align="center"
        min-width="100"
      />
      <vxe-column field="cnInStock" title="国内仓在仓库存" align="center" min-width="100">
        <template slot-scope="scope">{{ scope.row.transferTypeDict === 1 ? '/' : scope.row.cnInStock }}</template>
      </vxe-column>
      <vxe-column field="cnTransUncleared" title="国内仓调拨未结" align="center" min-width="100">
        <template slot-scope="scope">{{ scope.row.transferTypeDict === 1 ? '/' : scope.row.cnTransUncleared }}</template>
      </vxe-column>
      <vxe-column field="rps" title="RPS" align="center" min-width="100" />
      <vxe-column
        field="recentSale"
        title="近30天销量"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="recentReturn"
        title="近30天退货"
        align="center"
        min-width="100"
      />
      <vxe-column field="mps" title="MPS" align="center" min-width="100" />
      <vxe-column
        field="safeStockDays"
        title="安全库存天数"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="reserveSafeStock"
        title="预留安全库存"
        align="center"
        min-width="100"
      />
      <vxe-column
        field="notReserveAvailableInventory"
        title="预留外可用库存"
        align="center"
        min-width="100"
      />

      <vxe-column
        fixed="right"
        field="outStockChannelName"
        title="调出备货渠道"
        align="center"
        min-width="80"
      />
      <vxe-column
        fixed="right"
        field="inStockChannelName"
        title="调入备货渠道"
        align="center"
        min-width="80"
      />
      <vxe-column
        fixed="right"
        field="inWarehouseName"
        title="调入仓库"
        align="center"
        min-width="80"
      />
      <vxe-column
        fixed="right"
        field="outWarehouseName"
        title="调出仓库"
        align="center"
        min-width="100"
      />
      <vxe-column
        fixed="right"
        field="replenishNumber"
        title="实际调拨数量"
        align="center"
        min-width="60"
      />
      <vxe-column
        fixed="right"
        field="modifyByName"
        title="操作人"
        align="center"
        min-width="80"
      />
      <vxe-column
        fixed="right"
        field="modifyTime"
        title="操作时间"
        align="center"
        min-width="80"
      />
    </vxe-table>

    <Paging :pager="pager" end @pagination="pagerUpdate" />
  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
// import { handleDownload } from '@/utils'
import {
  usersList,
  queryStyleList,
  queryColorList,
  querySizeList,
  replenishRecordApi,
  exportReplenishRecord,
  dsbVersionList,
  StockChannelList,
  getImsWarehouseList
} from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'
// import { formatNum } from '@/utils/index.js'

export default {
  components: { Paging, Select },
  mixins: [commodityInfoDict],
  data() {
    return {
      showRow: false,
      form: {
        value1: [],
        style: ''
      },
      pager: {
        current: 1,
        size: 10,
        pages: 1,
        total: 0
      },
      brandOptions: [],
      StockChannelOptions: [],
      warehouseOption: [],
      useroptions: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      dsbVertionOptions: [],
      tableDatas: [
      ],
      Loading: false,
      exportLoading: false,
      detailData: [],
      dialogselectlist: []
    }
  },
  computed: {
    replenishRecordParams() {
      const [modifyTimeStart, modifyTimeEnd] = this.form.value1 || []
      const { operateId } = this.form
      const modifyIdList = operateId ? [operateId] : []

      return Object.assign({}, this.pager, this.form, {
        modifyIdList,
        modifyTimeStart,
        modifyTimeEnd
      })
    }
  },
  created() {},
  mounted() {
    this._queryStyleList()
    this._queryBrandList()
    this._queryColorList()
    this._querySizeList()
    this._usersList()
    this._dsbVersionList()
    this._StockChannelList()
    this._queryWarehouseList()
    this.queryClick(1)
  },
  methods: {
    showRowClick() {
      this.showRow = !this.showRow
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    },
    async queryClick(flag) {
      try {
        this.Loading = true
        flag && flag === 1 ? (this.pager.current = 1) : ''
        const {
          code,
          datas: { records, pager }
        } = await replenishRecordApi(this.replenishRecordParams, this.pager)
        if (code === 0) {
          this.pager = pager
          this.tableDatas = records
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.Loading = false
      }
    },
    // 导出
    async exportDetail() {
      try {
        this.exportLoading = true
        const params = {}
        Object.assign(params, this.replenishRecordParams)
        const { code, msg } = await exportReplenishRecord({ params: JSON.stringify(params) })
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
        } else {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.exportLoading = false
      } finally {
        this.exportLoading = false
      }
    },

    // 下拉信息获取
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    async _queryWarehouseList() {
      const { datas } = await getImsWarehouseList()
      this.warehouseOption = datas
    },
    async _dsbVersionList() {
      const { datas } = await dsbVersionList()
      this.dsbVertionOptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // styleSelectChange(value) {
    //   const data = this.styleOptions.find(item => item.id === value) || []
    //   this._queryColorList(data.id)
    //   this._querySizeList(data.id)
    // },
    async _queryColorList(id) {
      // const { datas } = await queryColorList({ styleId: id })
      const { datas } = await queryColorList()
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList()
      this.sizeOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">
</style>
